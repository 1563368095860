import logo from "./logo.svg";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="h1 my-5" style={{fontSize:'45px'}}>Hippo<span style={{color:'#be1e2d'}}>Fast</span></h1>
        <div
          id="carouselExampleCrossfade"
          className="carousel slide carousel-fade container-md"
          data-mdb-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-mdb-target="#carouselExampleCrossfade"
              data-mdb-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-mdb-target="#carouselExampleCrossfade"
              data-mdb-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-mdb-target="#carouselExampleCrossfade"
              data-mdb-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-mdb-target="#carouselExampleCrossfade"
              data-mdb-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-mdb-target="#carouselExampleCrossfade"
              data-mdb-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-mdb-target="#carouselExampleCrossfade"
              data-mdb-slide-to="5"
              aria-label="Slide 6"
            ></button>
            <button
              type="button"
              data-mdb-target="#carouselExampleCrossfade"
              data-mdb-slide-to="6"
              aria-label="Slide 7"
            ></button>
            <button
              type="button"
              data-mdb-target="#carouselExampleCrossfade"
              data-mdb-slide-to="7"
              aria-label="Slide 8"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="Slides/1.jpg"
                className="d-block w-100"
                alt="Wild Landscape"
              />
            </div>
            <div className="carousel-item">
              <img
                src="Slides/2.jpg"
                className="d-block w-100"
                alt="Camera"
              />
            </div>
            <div className="carousel-item">
              <img
                src="Slides/3.jpg"
                className="d-block w-100"
                alt="Exotic Fruits"
              />
            </div>
            <div className="carousel-item">
              <img
                src="Slides/4.jpg"
                className="d-block w-100"
                alt="Exotic Fruits"
              />
            </div>
            <div className="carousel-item">
              <img
                src="Slides/5.jpg"
                className="d-block w-100"
                alt="Exotic Fruits"
              />
            </div>
            <div className="carousel-item">
              <img
                src="Slides/6.jpg"
                className="d-block w-100"
                alt="Exotic Fruits"
              />
            </div>
            <div className="carousel-item">
              <img
                src="Slides/7.jpg"
                className="d-block w-100"
                alt="Exotic Fruits"
              />
            </div>
            <div className="carousel-item">
              <img
                src="Slides/8.jpg"
                className="d-block w-100"
                alt="Exotic Fruits"
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-mdb-target="#carouselExampleCrossfade"
            data-mdb-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-mdb-target="#carouselExampleCrossfade"
            data-mdb-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <h1 className="h1 my-5" style={{fontSize:'45px'}}>Contact<span style={{color:'#be1e2d'}}> Us</span></h1>
        <div className="row p-0 container-md mb-2">
          <div className="col-md-6 p-3">
            <img src="B1.jpg" data-aos="flip-left" data-aos-duration="2000" alt="" className="img-fluid" />
          </div>
          <div className="col-md-6 p-3">
            <img src="B2.jpg" alt="" data-aos="flip-right" data-aos-duration="2000" className="img-fluid" />
          </div>
        </div>
        <a href="Vcard.jpeg" download="HippoFast Visiting Card" className="btn btn-danger btn-md mb-5" style={{background:'#be1e2d'}}>Download Visiting Card</a>
      </header>
    </div>
  );
}

export default App;
